import React from 'react'

function PrivacyPolicy() {
  return (
    <div className='w-50 shadow-sm p-5 m-auto'>
        <h3 className='fontstylePoppin'>Privacy Policy</h3>
        <p className='fontstylePoppin fst-italic'>Effective Date: 01-01-2024</p>
        <p>
        At Ants Media, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines the types of information we collect, how we use and safeguard that information, and your choices regarding the use of your data. By using our platform and services, you agree to the terms outlined in this Privacy Policy.
        </p>
        <p className="fw-semibold">Information We Collect:</p>
         <ol>
            <li>
                <p>
                Personal Information: When you create an account, we may collect personal information such as your name, email address, contact number, and other relevant details.
                </p>
            </li>
            <li>
                <p>
                Event Information: To facilitate event ticketing, we collect information about the events you create or purchase tickets for, including event details, location, date, and time.
                </p>
            </li>
            <li>
                <p>
                Transaction Details: When you make a purchase on www.myfrontseat.in, we collect transaction details such as payment information and billing details.
                </p>
            </li>
            <li>
                <p>
                User Interaction: We may collect information about your interactions with our platform, including but not limited to, your browsing activity, searches, and preferences.
                </p>
            </li>
         </ol>
         <p className="fw-semibold">How We Use Your Information:</p>
         <ol>
            <li>
                <p>
                Personalization: We use your information to personalize your experience on Ants Media, providing you with relevant event recommendations and tailored content.
                </p>
            </li>
            <li>
                <p>
                Transaction Processing: Your information is used to process transactions, send confirmations, and provide you with event-related information.
                </p>
            </li>
            <li>
                <p>
                Communication: We may use your contact information to communicate with you about your account, events, updates, and promotional offers. You can opt-out of promotional emails at any time.
                </p>
            </li>
            <li>
                <p>
                Security and Fraud Prevention: We utilize your information to ensure the security of our platform, protect against fraud, and enforce our terms and policies.
                </p>
            </li>
            <li>
                <p>
                Analytics: We analyze user data to improve our platform, enhance user experience, and optimize our services.
                </p>
            </li>
         </ol>
         <p className="fw-semibold">Data Security:</p>
         <p>
         We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. Your payment information is securely processed through encrypted connections.
         </p>
         <p>Sharing of Information:</p>
         <p>
         We do not sell, rent, or trade your personal information to third parties. However, we may share information with trusted partners and service providers for the sole purpose of delivering our services and improving your experience on Ants Media.
         </p>
        <p className="fw-semibold">Your Choices:</p>
        <ul>
            <li>
                <p>
                Account Information: You can update or delete your account information at any time by accessing your account settings.
                </p>
            </li>
            <li>
                <p>
                Communication Preferences: You have the option to opt-out of promotional emails. However, transactional and account-related communications will be sent as necessary.
                </p>
            </li>
        </ul>
        <p className="fw-semibold">Changes to the Privacy Policy:</p>
        <p>
        Ants Media reserves the right to update this Privacy Policy as needed. We will notify users of any material changes, and your continued use of our platform after such modifications constitutes your acceptance of the updated terms.
        </p>
        <p className="fw-semibold">Contact Information:</p>
        <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at [info@myfrontseat.in].</p>
        <p>Thank you for entrusting Ants Media with your information. We are dedicated to maintaining the privacy and security of your data.</p>
        <p>Privacy Note:</p>
        <p>
        By using My Front Seat for ticket booking, you agree to the Privacy Policy available at www.myfrontseat.in/privacy giving your access to Ants Media and provisions of services by Ants Media to you. If you do not accept terms mentioned, you must not share any personal details and immediately leave www.myfrontseat.in
        </p>
    </div>
  )
}

export default PrivacyPolicy