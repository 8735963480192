import React, { useRef, useState } from "react";
import './UserProfile.css';
import NavBar from "../Components/NavBar";
import DatePicker from "react-datepicker";
import UserSideBar from "../Components/userSideBar";
import { BiSolidPencil } from "react-icons/bi";
import { API_URL } from "../../config";
import axios from "axios";
import LocationModal from "../Components/LocationModal";

const UserProfile = () => {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const user = JSON.parse(localStorage.getItem('userData'));

    const [firstName, setFirstName] = useState(user.firstName || "");
    const [lastName, setLastName] = useState(user.lastName || "");
    const [email, setEmail] = useState(user.email || "");
    const [dob, setDob] = useState(user.dob ? new Date(user.dob) : null);
    const [gender, setGender] = useState(user.gender || "");
    const [address, setAddress] = useState(user.address || ""); // Added default value
    const [phone, setPhone] = useState(user.phone || "");
    const [city, setCity] = useState(user.city || ""); // Added default value
    const [state, setState] = useState(user.state || ""); // Added default value
    const [pincode, setPincode] = useState(user.pincode || ""); // Added default value

    const handleEnable = (ref) => {
        setIsDisabled(false);
        ref.current.focus();
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        try {
            const updatingUser = {
                _id: user._id,
                firstName: firstName,
                lastName: lastName,
                email: email,
                dob: dob,
                gender: gender,
                address: address,
                phone: phone,
                city: city,
                state: state,
                pincode: pincode
            };

            const response = await axios.put(`${API_URL}/user/updateUser`, updatingUser);
            if (response.status === 200) {
                alert(response.data.message);
                // Optionally navigate to a different page or refresh the data
            } else {
                alert("Update failed: " + response.data.message);
            }
        } catch (error) {
            alert("Something went wrong: " + error.response?.data?.message || error.message);
        }
    };

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    return (
        <>
            <div className='profilepage'>
                {/* Show LocationModal */}
                {showLocationModal && (
                    <LocationModal
                        onClose={handleCloseLocationModal}
                        onLocationChange={handleLocationChange}
                    />
                )}
                <NavBar 
                userLocation={userLocation}
                onNext={() => setShowLocationModal(true)}/>
                <div className='row'>
                    <UserSideBar />
                    <div className='col-lg-10 col-md-10 col-sm-12 col-12'>
                        <form onSubmit={handleSubmit} style={{marginBottom:"10%"}}>
                            <div className='lists'>
                                <div className='d-flex justify-content-between border-bottom border-primary' style={{ marginTop: "34px" }}>
                                    <span className='profileheading fw-semibold'>Account Details</span>
                                </div>
                                <div className="row userInfo mt-2">
                                    <div className="col-lg-6 col-md-6 mb-3">
                                        <label className="fieldname" htmlFor="email">Email Address</label>
                                        <input
                                            className="Input"
                                            type="email"
                                            disabled={isDisabled}
                                            id="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            ref={emailRef}
                                        />
                                        <BiSolidPencil onClick={() => handleEnable(emailRef)} />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <label className="fieldname" htmlFor="phone ms-lg-2">Mobile Number</label>
                                        <input
                                            className="Input"
                                            type="tel"
                                            disabled={isDisabled}
                                            id="phone"
                                            name="phone"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            ref={phoneRef}
                                        />
                                        <BiSolidPencil onClick={() => handleEnable(phoneRef)} />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between border-bottom border-primary' style={{ marginTop: "34px" }}>
                                    <span className='profileheading fw-semibold'>Personal Details</span>
                                </div>
                                <div className="row personalDetailDiv mt-2">
                                    <div className="col-lg-5 personalDetail">
                                        <label className="fieldname" htmlFor="firstName">First Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-5 personalDetail">
                                        <label className="fieldname" htmlFor="lastName">Last Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-5 personalDetail">
                                        <label className="fieldname" htmlFor="dob">Birthday</label>
                                        <DatePicker
                                            className="form-control w-100"
                                            selected={dob}
                                            onChange={(date) => setDob(date)}
                                            placeholderText="DD/MM/YYYY"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                    <div className="col-lg-5 personalDetail">
                                        <label className="fieldname" htmlFor="gender">Gender</label>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <input
                                                    className="pers-selection"
                                                    type="radio"
                                                    id="female"
                                                    name="gender"
                                                    value="female"
                                                    checked={gender === "female"}
                                                    onChange={(e) => setGender(e.target.value)}
                                                />
                                                <label className="pers-gender" htmlFor="female">
                                                    Female
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className="pers-selection"
                                                    type="radio"
                                                    id="male"
                                                    name="gender"
                                                    value="male"
                                                    checked={gender === "male"}
                                                    onChange={(e) => setGender(e.target.value)}
                                                />
                                                <label className="pers-gender" htmlFor="male">
                                                    Male
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className="pers-selection"
                                                    type="radio"
                                                    id="other"
                                                    name="gender"
                                                    value="other"
                                                    checked={gender === "other"}
                                                    onChange={(e) => setGender(e.target.value)}
                                                />
                                                <label className="pers-gender" htmlFor="other">
                                                    Others
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 personalDetail">
                                        <label className="fieldname" htmlFor="address">Address</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="address"
                                            name="address"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-5 personalDetail">
                                        <label className="fieldname" htmlFor="city">City</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="city"
                                            name="city"
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-5 personalDetail">
                                        <label className="fieldname" htmlFor="state">State</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="state"
                                            name="state"
                                            value={state}
                                            onChange={(e) => setState(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-5 personalDetail">
                                        <label className="fieldname" htmlFor="pincode">Pincode</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            id="pincode"
                                            name="pincode"
                                            value={pincode}
                                            onChange={(e) => setPincode(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button className="updatebtn" type="submit">Update</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserProfile;
