import React, { useEffect, useState } from 'react'
import './TicketBooking.css'
import Logo from '../../Assets/logo_224x61-02.png'
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import { SlArrowDown } from 'react-icons/sl';
import { BsArrowLeft } from 'react-icons/bs';
import axios from 'axios';
import ReduceText from '../Interface';

function TicketBooking({ ticket, eventDetail, ticketids }) {
  // const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem('userData'));
  const Navigate = useNavigate();
  let [billing, SetBilling] = useState({
    ticketQuantity: 0,
    subTotal: 0,
    bookingFee: 0,
    gst: 0,
    total_amount: 0
  });

  // const handleCheckboxChange = (option) => {
  //   setSelectedOption(option);
  // };

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const formatTime = (timeString) => {
    if (timeString == null || timeString === undefined || timeString === '') {
        timeString = "00:00:00";
    }

    const dateObj = new Date(`1970-01-01T${timeString}Z`);
    let hours = dateObj.getUTCHours();
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    const formattedTime = `${hours}:${minutes} ${ampm}`;
    return formattedTime;
};

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = monthNames[dateObj.getMonth()]; // Get month name
    const year = String(dateObj.getFullYear()).slice(-2); // Get last two digits of the year
    return `${day}-${month}-${year}`;
  };

  const handleBilling = () => {
    console.log("handle billing called")
    let count = 0;
    let amount = 0;

    for (let i = 0; i < ticket.length; i++) {
      count = count + ticket[i].bookingQuantities;
      amount = amount + (ticket[i].bookingQuantities * ticket[i].ticket_price); 
    }
    console.log("GST ", eventDetail.basic_price)
    const gst9 = (Math.ceil(eventDetail.basic_price * 0.09 * 100) / 100).toFixed(2);
    console.log("GST 9", gst9)
    const fee = Number(eventDetail.basic_price) + (2 * Number(gst9));
    const totalAmount = amount + fee;

    SetBilling({
      ticketQuantity: count,
      subTotal: amount,
      bookingFee: fee,
      gst: Number(gst9),
      total_amount: parseInt(totalAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })),
    });
    console.log("Billing Values",billing)
  };

  useEffect(() => {
    console.log("ticket && ticket>0 && eventDetail : ", ticket && ticket>0 , ticket, eventDetail)
      handleBilling();
  }, [ticket, eventDetail]);
  

  //  Payment Process
   const [responseId, setResponseId] = useState("");
   const [responseState, setResponseState] = useState([]);
 
   const LoadScript = (src) => {
     return new Promise((resolve) => {
       const script = document.createElement("script");
       script.src = src;
       script.onload = () => { resolve(true) };
       script.onerror = () => { resolve(false) };
       document.body.appendChild(script);
     })
   }
   const CreateOrder = async () => {
   console.log("ticketids : ", ticketids)
    const  amt = 1*100;
      let data = JSON.stringify({
        amount: amt,
        currency: "INR",
        user_id : user._id,
        ticketDetails: ticketids,
        eventDetails: eventDetail,
      });

     let config = {
       method: "POST",
       maxBodyLength: Infinity,
       url: `${API_URL}/pay/payment-order`,
       headers: {
         "Content-Type": "application/json",
       },
       data: data
     }
     // api call
     const res = await axios.request(config)
       handleRazorpayScreen(res.data.amount, res.data.order_id)
   }
 
   const handleRazorpayScreen = async (amount,order_id) => {
     const res = await LoadScript("https://checkout.razorpay.com/v1/checkout.js");
     if (!res) {
       alert("Some error at razorpay");
       return;
     }
     const options = {
      //  "key": "rzp_test_LGorMBgiUWfWP4", // testing key
      //  "key": "rzp_live_JUrAAD75BEmsB7", // live key
      "key": "rzp_live_QQmdUNR3XVKX87",
       "amount": amount,
       "currency": "INR",
       "name": "My Front Seat",
       "description": "Payment to My-Front-Seat",
       "order_id": order_id,
       "image": Logo,
       "handler": function (response) {
         setResponseId(response.razorpay_payment_id);
         CheckPayment(order_id);
       },
       "prefill": {
         "name": "My Front Seat",
         "email": "myfrontseatin@gmail.com"
       },
       "theme": {
         "color": "#04092C"
       }
     }
     const PaymentObject = new window.Razorpay(options);
     PaymentObject.open();
   }

   const CheckPayment = async (order_id)=>{
    const response = await axios.get(`${API_URL}/pay/payment/status/${order_id}`);
    if(response.data.status){
      alert("Payment successful! Details have been saved.");
      Navigate('/yourtickets');
   }else{
    alert("Unable to process your payment");
   }
  }

  

  const handleProceedToPay = () => {
    if (localStorage.getItem("userData")) {
      // Navigate("/paymentgateway", { state: { billing } });
      CreateOrder();
      // if(responseId){
      //   SavePamentDetails()
      // }
    } else {
      Navigate('/login?source=tickets')
    }
  };

  return (
    <>
      <div className='bookingticketmainDiv' id="largeScreenViewPort">
        <div className='bookingticket'>
          <div className='row'>
            <div className='col-md-1 col-sm-1 col-1'>
              <span className='backarrowlink link-dark me-md-5 me-1 mt-2' onClick={() => Navigate(-1)}><BsArrowLeft size={30} /></span>
            </div>
            <div className='col-md-11 col-sm-11 col-11'>
              <div className='headingdiv'>
                <span className='heading'>{eventDetail.event_name}</span>
                <span className='subheading'>
                  {eventDetail.event_venue} {eventDetail.event_city} | {formatDate(eventDetail.event_starts_date)} | {formatTime(eventDetail.event_starts_Time)}</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <p className='bookinghead'>Booking Summary</p>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='eventimg'>
                <img
                  className="booking-img rounded-4 w-100 h-100"
                  src={`${API_URL}/uploads/${eventDetail.cover_image}`}
                  alt="booking"
                />
                <span className='ticketname'>
                  {
                    ticket && ticket.length > 0 ? (
                      ticket.map((tic, index) => (
                        <span key={tic._id}>
                          {index > 0 ? "," : ''} {tic.ticket_Name}
                        </span>
                      ))
                    ) : (
                      ""
                    )
                  }
                </span><br />
                <span className='quantity'>{billing.ticketQuantity} Tickets</span>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='bookingbifargation'>
                <div className="d-flex justify-content-between">
                  <p>Sub-total</p>
                  <p>₹ {billing.subTotal.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
                <div>
                  <div className="d-flex justify-content-between" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <p>Booking Fee <SlArrowDown className='arrowdown ms-3' /></p>
                    <p>₹ {billing.bookingFee.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  </div>
                  {/* Collapse div */}
                  <div className="collapse w-100 border-top border-bottom border-secondary-subtle mt-1" id="collapseExample">
                    <div className="d-flex justify-content-between mb-1">
                      <span>Base Price</span>
                      <span>₹ {Number(eventDetail.basic_price).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <span>Central GST(CGST)@ 9%</span>
                      <span>₹ {Number(billing.gst).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>State GST(SGST)@ 9%</span>
                      <span>₹ {Number(billing.gst).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                  </div>
                </div>
                <div className="mt-2 d-flex align-items-end justify-content-between ">
                  <span className="fw-bold">Total Amount</span>
                  <span className="fw-bold">₹ {billing.total_amount}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6 d-flex flex-column">
            </div>
            <div className="col-6">
              <div className="have-discount">Have a discount code?</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="code w-100"
                  placeholder="Enter code"
                />
                <div className="apply">Apply</div>
              </div>
            </div>
          </div>
        </div>

        <div className="proceedpay-div">
          <button className="proceedpay-btn" onClick={handleProceedToPay}>Proceed to Pay</button>
        </div>
      </div>

      <div id="smallScreenViewPort">
        <div className="row headerpart">
          <div className="col-1 d-flex justify-content-center align-items-center">
            <span className='backarrow link-dark' onClick={() => Navigate(-1)}><BsArrowLeft size={24} /></span>
          </div>
          <div className='col-11 d-flex justify-content-center align-items-center'>
            <span className='topheading fw-semibold'>Confirm Your Details</span>
          </div>
        </div>
        <div className='ticketbody'>
          <p className='summaryheading fw-semibold'>Booking Summary</p>
        </div>
        <div className='ticket-Details mt-0 card mx-3'>
          <div className='ticketNAme'>
            <span>
            {ReduceText(eventDetail?.event_name || '')}
            </span>
            <span className='ticketprice'>
              ₹ {billing.subTotal.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
          </div>
          <hr />
          <div className='ticketdatetime'>
            <span> {formatDate(eventDetail.event_starts_date)}  {formatTime(eventDetail.event_starts_Time)}</span>
            <span>{eventDetail.event_city} </span>
          </div>
          <hr />
          <div className='d-flex justify-content-between'>
            <span className='ticketNAme'>
              {
                ticket && ticket.length > 0 ? (
                  ticket.map((tic, index) => (
                    <span key={tic._id}>
                      {index > 0 ? "," : ''} {tic.ticket_Name}
                    </span>
                  ))
                ) : (
                  ""
                )
              }
            </span>
            <span className='quantity'>{billing.ticketQuantity} Tickets</span>
          </div>
        </div>
        <div className=" mt-3 mx-3">
          <div className="ticketname mb-2">Have a discount code?</div>
          <div className="d-flex">
            <input
              type="text"
              className="code w-100"
              placeholder="Enter code"
            />
            <div className="apply">Apply</div>
          </div>
        </div>
        <div className='bookingDetails card mx-3'>
          <div className="d-flex justify-content-between">
            <p className='ticketprice'>Sub-total</p>
            <p className='ticketprice'>₹ {billing.subTotal.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          </div>
          <div>
            <div className="d-flex justify-content-between" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              <p className='ticketprice'>Booking Fee <SlArrowDown className='arrowdown ms-3' /></p>
              <p className='ticketprice'>₹ {billing.bookingFee.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            {/* Collapse div */}
            <div className="collapse w-100 border-top border-bottom border-secondary-subtle mt-1" id="collapseExample">
              <div className="d-flex justify-content-between mb-1">
                <span className='ticketprice fw-normal'>Base Price</span>
                <span className='ticketprice fw-normal'>₹ {Number(eventDetail.basic_price).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
              <div className="d-flex justify-content-between mb-1">
                <span className='ticketprice fw-normal'>Central GST(CGST)@ 9%</span>
                <span className='ticketprice fw-normal'>₹ {Number(billing.gst).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className='ticketprice fw-normal'>State GST(SGST)@ 9%</span>
                <span className='ticketprice fw-normal'>₹ {Number(billing.gst).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <p className="total">Total Amount</p>
            <p className="total">₹ {billing.total_amount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          </div>
        </div>
        <div className='smallfooterPart py-2'>
          <div className='text-center'>
            <button className="proceedpay-btn" onClick={handleProceedToPay}>Proceed to Pay</button>
          </div>
        </div>
      </div>

    </>
  );
}

export default TicketBooking