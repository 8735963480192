import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  // apiKey: "AIzaSyBBA5iHXupY7XWlVvF8Xm-Zc0HCC-Lv1d8",
  // authDomain: "mfs-live.firebaseapp.com",
  // projectId: "mfs-live",
  // storageBucket: "mfs-live.appspot.com",
  // messagingSenderId: "805155199510",
  // appId: "1:805155199510:web:037c8a73ea4e0379883337"

  apiKey: "AIzaSyBBA5iHXupY7XWlVvF8Xm-Zc0HCC-Lv1d8",
  authDomain: "mfs-live.firebaseapp.com",
  projectId: "mfs-live",
  storageBucket: "mfs-live.appspot.com",
  messagingSenderId: "805155199510",
  appId: "1:805155199510:web:037c8a73ea4e0379883337",
  measurementId: "G-E7J1RPXRGK"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
  
// export { auth };
export default auth;
 