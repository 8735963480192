import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import './BrowseByCategory.css';
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

function BrowseByCategory() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  const handleEventCategory = (cat) => {
    navigate(`/category/${cat}`);
  };

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth < 560) {
        setItemsPerPage(2);
      } else {
        setItemsPerPage(4);
      }
    };

    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);

    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const listofCategory = await axios.get(`${API_URL}/api/category`);
      setCategories(listofCategory.data);
    })();
  }, []);

  const nextSlide = () => {
    if (currentIndex + itemsPerPage < categories.length) {
      setCurrentIndex((prevIndex) => prevIndex + 2);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 2);
    }
  };

  const visibleCategories = categories.slice(currentIndex, currentIndex + itemsPerPage);

  return (
    <>
      <div className='categorylistDiv'>
        <hr className='horizontalLine' id="forsmallScreen" />
        <h4 className='browseHeading'>Browse By Category</h4>
        <hr className='horizontalLine' id="forlargeScreen" />
        <div className='d-flex justify-content-start align-items-center browseCarousel'>
          <button
            className={`carousel-control prev ${currentIndex === 0 ? 'disabled' : ''}`}
            onClick={prevSlide}
            disabled={currentIndex === 0}>
            <IoChevronBackOutline className='lefticon' size={20} />
          </button>
          <div className="carousel-container">
            <div className="carousel-wrapper d-flex justify-content-center">
              {visibleCategories.map((category, index) => (
                <div className="card categorycard" key={index}>
                  <span className="text-center categoryblock m-auto"
                    onClick={() => handleEventCategory(category.category)}>
                    {category.category}
                  </span>
                  <div className="card-img" onClick={() => handleEventCategory(category.category)}>
                    <img className="category-img" src={category.image} alt={category.category} />
                  </div>
                  {/* <span className="text-center lowercategoryblock m-auto" id="forsmallScreen"
                  onClick={() => handleEventCategory(category.category)}>
                  {category.category}
                </span> */}
                </div>
              ))}
            </div>
          </div>
          <button
            className={`carousel-control next ${currentIndex + itemsPerPage >= categories.length ? 'disabled' : ''}`}
            onClick={nextSlide}
            disabled={currentIndex + itemsPerPage >= categories.length}>
            <IoChevronForwardOutline className='nextIcon' size={20} />
          </button>
        </div>
      </div>

    </>
  );
}

export default BrowseByCategory;
