import React, { useEffect, useState } from 'react'
import NavBar from '../Components/NavBar';
import LocationModal from '../Components/LocationModal';
import Carousel from '../Components/Carousel';
import BrowseByCategory from '../Components/BrowseByCategory';
import Footer from '../Components/Footer';
import Trending from '../Components/Trending';

function HomePage() {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(true);


    // useEffect to manage modal visibility on first visit
    useEffect(() => {
        // Check if it's the first visit (could be based on localStorage or other logic)
        const isFirstVisit = !localStorage.getItem('visitedBefore');

        if (isFirstVisit) {
            setShowLocationModal(true);
            localStorage.setItem('visitedBefore', true);
        } else {
            setShowLocationModal(false);
        }
    }, []);

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };
    return (
        <div>
            {/* Show LocationModal */}
            {showLocationModal && (
                <LocationModal
                    onClose={handleCloseLocationModal}
                    onLocationChange={handleLocationChange}
                />
            )}

            {/* Render Navbar Component */}
            <NavBar 
            userLocation={userLocation} 
            onNext={() => setShowLocationModal(true)}/>

            {/* Render Carousel */}
            <Carousel />

            {/* Browse By Category */}
            <BrowseByCategory />

            {/* trending event list */}
            <Trending userLocation={userLocation}/>

            <Footer />
        </div>
    )
}

export default HomePage