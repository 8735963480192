import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API_URL } from '../../config';
import LocationModal from '../Components/LocationModal';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import axios from 'axios';
import ProductDetail from '../Components/ProductDetail';

function ProductPage() {
    const { id } = useParams();
    const [eventDetail, setEventDetail] = useState(null);
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };
    useEffect(() => {
        const getEventDetail = async () => {
            const event = await axios.get(`${API_URL}/api/eventdetail/${id}`)
            setEventDetail(event.data);
        };
        getEventDetail();
    }, [id]);

    return (
        <div>
            <NavBar userLocation={userLocation} onNext={() => setShowLocationModal(true)} />
            {eventDetail ? (
                <>
                    {/* Render Location component as a modal */}
                    {showLocationModal && (
                        <LocationModal
                            onClose={() => setShowLocationModal(false)}
                            onLocationChange={handleLocationChange}
                        />
                    )}
                    {/* Product Detail */}
                    <ProductDetail eventDetail={eventDetail} />
                </>
            ) : (
                <div>Something Went Wrong....</div>
            )}

            <Footer />
        </div>
    )
}

export default ProductPage