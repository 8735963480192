import React, { useEffect, useReducer, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import LocationModal from "../Components/LocationModal";
import NavBar from "../Components/NavBar";
import TicketBooking from "../Components/TicketBooking";
import { API_URL } from "../../config";

// Initial state for the reducer
const initialState = {
    ticket: [],
    eventDetail: {},
};

// Reducer function to manage ticket and event detail state
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_RESPONSE_DATA':
            return {
                ...state,
                ticket: action.payload.ticket,
                eventDetail: action.payload.event,
            };
        default:
            return state;
    }
};

function BookingPage() {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState); // Using useReducer for managing ticket and event detail
    const { event_id } = useParams();
    const location = useLocation();

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    // Function to fetch data from API
    const getData = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/invoice/${event_id}`);
            if (response.status === 200) {
                const storeData = response.data;
                // Dispatch action to update ticket and event detail
                dispatch({
                    type: 'SET_RESPONSE_DATA',
                    payload: {
                        ticket: storeData.ticket,
                        event: storeData.event,
                    },
                });
            }
        } catch (error) {
            alert("Unable to get data");
        }
    };

    useEffect(() => {
        getData();
    }, [event_id]);

    return (
        <div>
            {/* Show LocationModal */}
            {showLocationModal && (
                <LocationModal
                    onClose={handleCloseLocationModal}
                    onLocationChange={handleLocationChange}
                />
            )}
            <div id="hideforSmallDevices">
                <NavBar
                    userLocation={userLocation}
                    onNext={() => setShowLocationModal(true)}
                />
            </div>

            {/* Pass state values (ticket and eventDetail) as props to TicketBooking */}
            <TicketBooking eventDetail={state.eventDetail} ticket={state.ticket} ticketids={location.state.bookingQuantities} />
        </div>
    );
}

export default BookingPage;
