import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LocationModal from "../Components/LocationModal";
import NavBar from "../Components/NavBar";
import SelectTickets from "../Components/SelectTickets";

function TicketPage() {
    const { id } = useParams();
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);


    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    return (
        <div>
            {/* Show LocationModal */}
            {showLocationModal && (
                <LocationModal
                    onClose={handleCloseLocationModal}
                    onLocationChange={handleLocationChange}
                />
            )}
            <div id="hideforSmallDevices">
                <NavBar
                    userLocation={userLocation}
                    onNext={() => setShowLocationModal(true)} />
            </div>

            <SelectTickets id={id} />

        </div>
    );
}

export default TicketPage;
