import logo from './logo.svg';
import './App.css';
import HomePage from './User/Pages/HomePage';
import { Route, Routes } from 'react-router-dom';
import CategoryPage from './User/Pages/CategoryPage';
import ProductPage from './User/Pages/ProductPage';
import TicketPage from './User/Pages/TicketPage';
import BookingPage from './User/Pages/BookingPage';
import LoginPage from './User/Pages/LoginPage';
import SignUpPage from './User/Pages/SignUpPage';
// import PaymentGateway from './User/Pages/PaymentGateway';
import PrivacyPolicy from './User/Pages/PrivacyPolicy';
import TermsCondition from './User/Pages/TermsCondition';
import UserProfile from './User/Pages/UserProfile';
import YourticketsPage from './User/Pages/YourTicketPage';
import ListyourEventPage from './User/Pages/ListyourEventPage';
import HostedEventPage from './User/Pages/HostedEventPage';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/category/:category" element={<CategoryPage />} />
        <Route path="/productpage/:id" element={<ProductPage />} />
        <Route path="/ticketpage/:id" element={<TicketPage />} />
        <Route path="/eventbooking/:event_id" element={<BookingPage />} />
        {/* <Route path="/paymentgateway" element={<PaymentGateway />} /> */}
        <Route path="/policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/yourtickets" element={<YourticketsPage />} />
        <Route path="/list-your-event" element={<ListyourEventPage />} />
        <Route path="/hosted-events" element={<HostedEventPage />} />

      </Routes>
    </div>
  );
}

export default App;
