import React, { useState } from "react";
import './ListyourEventPage.css';
import NavBar from "../Components/NavBar";
import UserSideBar from "../Components/userSideBar";
import LocationModal from "../Components/LocationModal";
import { BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";

const ListyourEventPage = () => {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);


    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    return (
        <>
            <div className='profilepage'>
                {/* Show LocationModal */}
                {showLocationModal && (
                    <LocationModal
                        onClose={handleCloseLocationModal}
                        onLocationChange={handleLocationChange}
                    />
                )}
                <NavBar
                    userLocation={userLocation}
                    onNext={() => setShowLocationModal(true)} />
                <div className='row'>
                    <UserSideBar />
                    <div className='col-lg-10 col-md-10 col-sm-12 col-12'>
                        <div className="d-flex justify-content-center">
                            <Link to='/details' className='createeventbtn text-center'><BiPlus size={15} /> Create Event</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListyourEventPage;
