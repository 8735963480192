import axios from 'axios';
import './SelectTickets.css'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import { BsArrowLeft } from "react-icons/bs";
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';

function SelectTickets({ id }) {
    const [ticketList, setTicketList] = useState([]);
    const [eventData, SetEventData] = useState([]);
    const Navigate = useNavigate();
    const [bookingQuantities, setBookingQuantities] = useState({});

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(`${API_URL}/api/eventdetail/${id}`);
                setTicketList(data[0].ticket);
                SetEventData(data[0]);
            } catch (error) {
                console.error("Error fetching ticket data:", error);
            }
        })();
    }, [id]);

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const month = monthNames[dateObj.getMonth()];
        const year = String(dateObj.getFullYear()).slice(-2);
        return `${day}-${month}-${year}`;
    };

    const formatTime = (timeString) => {
        const dateObj = new Date(`1970-01-01T${timeString}Z`);
        let hours = dateObj.getUTCHours();
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${ampm}`;
    };
    const incrementQuantity = (ticketId) => {
        setBookingQuantities((prevQuantities) => ({
            ...prevQuantities,
            [ticketId]: (prevQuantities[ticketId] || 0) + 1,
        }));
    };

    const decrementQuantity = (ticketId) => {
        setBookingQuantities((prevQuantities) => ({
            ...prevQuantities,
            [ticketId]: Math.max((prevQuantities[ticketId] || 0) - 1, 0),
        }));
    };

    const handleTicketBooking = async () => {
        try {
            const response = await axios.post(`${API_URL}/ticket/booktickets`, {
                bookingQuantities,
            });
            if (response.status === 200) {
                Navigate(`/eventbooking/${eventData._id}`, { state: { bookingQuantities } })
            }
        } catch (error) {
            console.error("Error booking tickets:", error);
        }
    };

    
    const getTotalQuantity = () => {
        return Object.values(bookingQuantities).reduce((total, quantity) => total + quantity, 0);
    };

    const getTotalPrice = () => {
        return ticketList.reduce((total, ticket) => {
            const quantity = bookingQuantities[ticket._id] || 0;
            return total + (quantity * ticket.ticket_price);
        }, 0);
    };

    const isButtonDisabled = getTotalQuantity() === 0;

    return (
        <>
            <div className='ticketlist'>
                <div className='row' id="largedeviceview">
                    <div className='col-md-1 col-sm-1'>
                        <span className='backarrowlink link-dark mt-2' onClick={() => Navigate(-1)}><BsArrowLeft size={30} /></span>
                    </div>
                    <div className='col-md-11 col-sm-11'>
                        <div className='d-flex flex-column justify-content-center'>
                            <span className='event-name'>{eventData.event_name}</span>
                            <span className='event-detail'>{eventData.event_venue ? eventData.event_venue + "," : ''} {eventData.event_city} |
                                {formatDate(eventData.event_starts_date)} | {formatTime(eventData?.event_starts_Time)}
                            </span>
                        </div>
                    </div>
                </div>
                <div id="smalldeviceview">
                <div className="row">
                    <div className="col-1 d-flex justify-content-center align-items-center">
                        <span className='backarrow link-dark' onClick={() => Navigate(-1)}><BsArrowLeft size={24} /></span>
                    </div>
                    <div className='col-11 d-flex flex-column justify-content-center align-items-center'>
                        <span className='event-name fw-semibold'>{eventData.event_name}</span>
                        <span className='event-detail'>
                            {formatDate(eventData.event_starts_date)}  {formatTime(eventData?.event_starts_Time)}
                            {eventData.event_venue ? eventData.event_venue + "," : ''} {eventData.event_city}
                        </span>
                    </div>
                </div>
                </div>
                <div className='head'>Select Your Front Seat</div>
                <div style={{marginBottom:"30%"}}>
                    {
                        ticketList.length > 0 ? (
                            ticketList.map((ticket) => (
                                <div className='listoftickets rounded' key={ticket._id}>
                                    <div className='ticketDetails'>
                                        <span className='ticketname'>{ticket.ticket_Name}</span>
                                        <span className='ticketdescription'>{ticket.ticket_description}</span>
                                    </div>
                                    <div>
                                        <span className='ticketname ms-3'> ₹ {ticket.ticket_price}</span>
                                        <span className="d-flex pt-1">
                                            <CiCircleMinus className='couting' onClick={() => decrementQuantity(ticket._id)} />{" "}
                                            <span className="ticketcount mx-2"> {bookingQuantities[ticket._id] || 0} </span>
                                            <CiCirclePlus className='couting text-primary' onClick={() => incrementQuantity(ticket._id)} />{" "}
                                        </span>
                                    </div>
                                </div>
                            ))) :
                            <p>No Tickets are available</p>
                    }
                </div>
            </div>
            {/* Footer Part */}
            <div className='footerPart py-3' id="largedeviceview">
                <div className='footer-content'>
                    <div className='d-flex flex-column'>
                        <span className='ticketPrice fw-semibold'>₹{getTotalPrice()}</span>
                        <span className='totalCount'>{getTotalQuantity()} seats</span>
                    </div>
                    <button className={`proceedbtn ${isButtonDisabled ? "btnisDisabled" : "btnisEabled"}`}
                        disabled={isButtonDisabled}
                        onClick={handleTicketBooking}
                    >
                        Proceed</button>
                </div>
            </div>

            <div className='smallfooterPart py-2' id="smalldeviceview">
                <div className='footer-content px-3'>
                    <div className='d-flex flex-column'>
                        <span className='ticketPrice fw-semibold mb-2'>₹{getTotalPrice()}</span>
                        <span className='totalCount'>{getTotalQuantity()} seats</span>
                    </div>
                    <button className={`proceedbtn mt-3 ${isButtonDisabled ? "btnisDisabled" : "btnisEabled"}`}
                        disabled={isButtonDisabled}
                        onClick={handleTicketBooking}
                    >
                        Proceed</button>
                </div>
            </div>
        </>
    )
}

export default SelectTickets





