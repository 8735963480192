import React, { useState } from 'react';
import './ProductDetail.css';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import instaLogo from '../../Assets/Instagram.png';
import spotifyLogo from '../../Assets/spotify.png';
import { SlArrowDown } from 'react-icons/sl';
import { TbMapPin } from "react-icons/tb";
import { Calendar, Clock } from 'react-feather';
import ReduceText from '../Interface';
import { GoShareAndroid } from "react-icons/go";
import { FiLink } from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io";

function ProductDetail({ eventDetail }) {
    const navigate = useNavigate();
    const event = eventDetail[0];
    const handleTicketSelection = () => {
        navigate(`/ticketpage/${event._id}`);
    };

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const month = monthNames[dateObj.getMonth()];
        const year = String(dateObj.getFullYear()).slice(-2);
        return `${day}-${month}-${year}`;
    };

    const formatTime = (timeString) => {
        if (timeString == null || timeString === undefined || timeString === '') {
            timeString = "00:00:00";
        }

        const dateObj = new Date(`1970-01-01T${timeString}Z`);
        let hours = dateObj.getUTCHours();
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        const formattedTime = `${hours}:${minutes} ${ampm}`;
        return formattedTime;
    };

    const lowestTicket = (ticketprices) => {
        let lowestPrice = ticketprices[0].ticket_price;
        for (let i = 1; i < ticketprices.length; i++) {
            if (ticketprices[i].ticket_price !== 0 && lowestPrice > ticketprices[i].ticket_price) {
                lowestPrice = ticketprices[i].ticket_price;
            }
        }
        return lowestPrice;
    };

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const getText = () => {
        if (isExpanded) {
            return event.event_description;
        }
        return event.event_description.length > 100 ? event.event_description.substring(0, 50) + "..." : event.event_description;
    };

    return (
        <>
            <div className='productDetailDiv' id="desktopView">
                <div className='row detailDiv'>
                    <div className='col-lg-6 col-md-6'>
                        <div className='Event_imageDiv'>
                            <img src={`${API_URL}/uploads/${event.cover_image}`} className='w-100 h-100 rounded-4' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <div className='event-info'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span className='event_name'>{ReduceText(event?.event_name || '')}</span>
                                    <div class="dropdown">
                                    <span data-bs-toggle="dropdown" aria-expanded="false"><GoShareAndroid size={30} style={{ color: "#2C9CF0" }} /></span>
                                        <ul class="dropdown-menu">
                                            <li><button class="dropdown-item" type="button">Copy link <FiLink/></button></li>
                                            <li><button class="dropdown-item" type="button">WhatsApp <IoLogoWhatsapp /></button></li>
                                            <li><button class="dropdown-item" type="button">Facebook</button></li>
                                            <li><button class="dropdown-item" type="button">Twitter</button></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <span className='event-category me-4' style={{ width: "5rem" }}>{event.category}</span>
                                    <span className='event-detail'>
                                        EMD | {event.Addon?.[0]?.Event_lang ? event.Addon[0].Event_lang : "Not Mention"} | {event.Addon?.[0]?.Age_restriction ? event.Addon[0].Age_restriction + "yrs +" : "0"} | 6hrs
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className='mb-2'>
                                    <span className='event-detail'>
                                        <TbMapPin /> {event.event_venue ? event.event_venue + ',' : ''} {event.event_city}
                                    </span>
                                    <Link className='directionspan' to="#"><span >Get Direction</span></Link>
                                </div>
                                <div className='event-schedule mb-2'>
                                    <span className='event-timing fw-semibold'>
                                        <Calendar /> {formatDate(event.event_starts_date)}
                                    </span>
                                    <span className='event-timing fw-semibold'>
                                        <Clock /> {formatTime(event?.event_starts_Time)} - {formatTime(event?.event_ends_Time)}
                                    </span>
                                </div>
                                <div className='d-flex justify-content-between align-items-end me-lg-5 me-md-2'>
                                    <p>
                                        <span className='ticket-amt'>₹ {event.ticket.length > 0 ? lowestTicket(event.ticket) : "0"} </span>
                                        <span className='fw-normal text-secondary' style={{ color: "#6A6A6A", fontSize: "24px" }}>onwards</span>
                                    </p>
                                </div>
                                <button className='booking-btn fs-5 rounded-3' onClick={handleTicketSelection}>Book Now</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row aboutArtistDiv'>
                    <div className='col-lg-6'>
                        <div className='aboutArtist border border-light-subtle d-flex flex-column'>
                            <span className='fw-semibold profileHead mb-1'>About: </span>
                            <span className='profilesub'>{getText()}</span>
                            {event.event_description.length > 100 && (
                                <Link className='fw-normal fs-6' to='#' onClick={toggleReadMore}>
                                    {isExpanded ? "Read Less" : "Read More"}
                                </Link >
                            )}
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='aboutArtist border border-light-subtle'>
                            <span className='fw-semibold profileHead'>Artist: </span>
                            <div className='artistSocialdetail d-flex align-items-center justify-content-between' style={{ height: "144px" }}>
                                <div>
                                    <img className="user-image" src={`${API_URL}/uploads/${event.Addon?.[0]?.Artist_pic}`} alt="User" />
                                </div>
                                <div className='d-flex align-items-center'>
                                    <p className='profilesub'>{event.Addon?.[0]?.Artist}</p>
                                </div>
                                <div className='mt-lg-2'>
                                    <Link to={event.Addon?.[0]?.Instagram_account ? event.Addon[0].Instagram_account : "#"}>
                                        <img className="insta-image" src={instaLogo} alt="Instagram" />
                                    </Link>
                                </div>
                                <div>
                                    <Link to={event.Addon?.[0]?.Spotify_account ? event.Addon[0].Spotify_account : "#"}>
                                        <img className="spotify-image" src={spotifyLogo} alt="Spotify" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lastDiv'>
                    <div className='extraDetail fw-semibold mt-1 px-1'
                        data-bs-toggle="collapse" data-bs-target="#venueLoyout" aria-expanded="false" aria-controls="venueLoyout">
                        <span>Venue Layout</span>
                        <span className='me-3'><SlArrowDown className='arrowdown' size={20} /></span>
                    </div>
                    {/* Collapse Div for Venue Layout */}
                    <div className="collapse my-3" id="venueLoyout">
                        {
                            (!event.Addon?.[0]?.Venuepicture) ?
                                <div className='p-5 w-75 m-auto border border-dark m-5 text-center' style={{ minHeight: "250px", backgroundColor: "peach" }}>
                                    <span className='fs-1 fw-bold'>Venue Layout Image</span>
                                </div> :
                                <img className='w-100 h-100' src={`${API_URL}/uploads/${event.Addon[0]?.Venuepicture}`} />
                        }
                    </div>
                    <div className='extraDetail fw-semibold mt-1 px-1'
                        data-bs-toggle="collapse" data-bs-target="#termscondition" aria-expanded="false" aria-controls="termscondition">
                        <span className='fw-semibold'>Terms & Conditions</span>
                        <span className='me-3'><SlArrowDown className='arrowdown' size={20} /></span>
                    </div>
                    {/* Collapse Div for Venue Layout */}
                    <div className="collapse my-3" id="termscondition">
                        <div>
                            {/* <ol className='profilesub'>
                                <li>{event.termsCondition}</li>
                            </ol> */}
                            <p className='profilesub'>
                                {event.termsCondition}
                            </p>
                        </div>
                    </div>
                    <div className='extraDetail fw-semibold mt-1  px-1'
                        data-bs-toggle="collapse" data-bs-target="#guideline" aria-expanded="false" aria-controls="guideline">
                        <span>Guidelines</span>
                        <span className='me-3'><SlArrowDown className='arrowdown' size={20} /></span>
                    </div>
                    {/* Collapse Div for Venue Layout */}
                    <div className="collapse my-3" id="guideline">
                        <div>
                            {/* <ol className='profilesub'>
                                <li>{event.guidelines}</li>
                            </ol> */}
                            <p className='profilesub'>
                                {event.guidelines}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Product Detail Page for Mobile View */}
            <div className='mb-5' id='mobileView'>
                <div className='Event_imageDiv'>
                    <img src={`${API_URL}/uploads/${event.cover_image}`}
                        className='w-100 h-100' />
                </div>
                <div className='p-3 d-flex flex-column px-4'>
                    <span className='fw-bold' style={{ fontFamily: "Poppins", fontSize: "25px" }}>
                        {event.event_name}
                    </span>
                    <div className='event-lang mt-2'>
                        <span className='event-category me-3'>{event.category}</span>
                        <span className='event-lang'>
                            EMD | {event.Addon?.[0]?.Event_lang ? event.Addon[0].Event_lang : "Not Mention"} | {event.Addon?.[0]?.Age_restriction ? event.Addon[0].Age_restriction + "yrs +" : "0"} | 6hrs
                        </span>
                    </div>
                    <div>
                        <div className='event-lang mt-2'>
                            <span>
                                <TbMapPin /> {event.event_venue ? event.event_venue + ',' : ''} {event.event_city}
                            </span>
                        </div>
                        <span style={{ fontSize: "12px", color: "#2C9CF0", fontFamily: "Poppins", marginTop: "5px" }}>Get Direction</span>
                    </div>
                    <div className='event-datetime'>
                        <span className='me-5 fw-semibold'><Calendar className='me-1' /> {formatDate(event.event_starts_date)}</span>
                        <span className='fw-semibold ms-5'><Clock className='me-1' /> {formatTime(event?.event_starts_Time)}</span>
                    </div>
                </div>
                <div className='artistDivabout p-3' style={{ marginTop: "15px" }}>
                    <span className='artistHeading fw-semibold'>About: </span>
                    <p className='contents fw-semibold'>{getText()}</p>
                    {event.event_description.length > 100 && (
                        <Link className='fw-normal' to='#' onClick={toggleReadMore}>
                            {isExpanded ? "Read Less" : "Read More"}
                        </Link >
                    )}
                </div>
                <div className='event-price px-2'>
                    <p className='mt-4'><span className='event-ticket'>₹ {event.ticket.length > 0 ? lowestTicket(event.ticket) : "0"} </span><span style={{ color: "#6A6A6A", fontFamily: "Poppins", fontSize: "16px" }}>onwards</span></p>
                    <button className='btn-booking text-white' onClick={handleTicketSelection}>Book Now</button>
                </div>
                <div className='artistDiv p-3'>
                    <span className='artistHeading fw-semibold'>Artist :</span>
                    <div className='row'>
                        <div className='col-3'>
                            <img className="user-image" src={`${API_URL}/uploads/${event.Addon?.[0]?.Artist_pic}`} alt="User" />
                        </div>
                        <div className='col-6 d-flex align-items-center'>
                            <p className='fw-semibold'>{event.Addon?.[0]?.Artist}</p>
                        </div>
                        <div className='col-3'>
                            <Link to={event.Addon?.[0]?.Instagram_account ? event.Addon[0].Instagram_account : "#"}>
                                <img src={instaLogo} alt="Instagram" />
                            </Link>
                        </div>
                        <div className='col-12 d-flex align-items-end justify-content-center'>
                            <Link to={event.Addon?.[0]?.Spotify_account ? event.Addon[0].Spotify_account : "#"}>
                                <img src={spotifyLogo} alt="spotify" />
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <div className='artistDivabout p-3'>
                    <span className='artistHeading fw-semibold'>Why should attend? </span>
                    <p className='contents fw-semibold'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis eum et iusto. Quas consequuntur dolorem dolorum odio delectus quisquam voluptas blanditiis repellat assumenda illo possimus, nobis laudantium modi quia facilis.</p>
                </div> */}
                <div className='extraDetail fw-semibold mt-5'
                    data-bs-toggle="collapse" data-bs-target="#venueLoyout" aria-expanded="false" aria-controls="venueLoyout">
                    <span>Venue Layout</span>
                    <span><SlArrowDown className='arrowdown' /></span>
                </div>
                {/* Collapse Div for Venue Layout */}
                <div className="collapse my-3" id="venueLoyout">
                    {
                        (!event.Addon?.[0]?.Venuepicture) ?
                            <div className='p-5 w-100 m-auto border border-dark m-5 text-center' style={{ minHeight: "250px", backgroundColor: "peach" }}>
                                <span className='fs-1 fw-bold'>Venue Layout Image</span>
                            </div> :
                            <img className='w-100 h-100' src={`${API_URL}/uploads/${event.Addon[0]?.Venuepicture}`} />
                    }
                </div>
                {/* <div className='extraDetail fw-semibold'>
                    <span>FAQs</span>
                    <span><SlArrowDown className='arrowdown' /></span>
                </div> */}
                {/* <div className='extraDetail fw-semibold'>
                    <span>Privacy Policy</span>
                    <span><SlArrowDown className='arrowdown' /></span>
                </div> */}

                <div className='extraDetail fw-semibold'
                    data-bs-toggle="collapse" data-bs-target="#ternCondition" aria-expanded="false" aria-controls="ternCondition">
                    <span>Terms & Conditions</span>
                    <span><SlArrowDown className='arrowdown' /></span>
                </div>
                {/* Collapse Div for Terms & Conditions */}
                <div className="collapse my-3" id="ternCondition">
                    <p className='contents ps-4 fw-semibold'>
                        {event.termsCondition}
                    </p>
                </div>
                <div className='extraDetail fw-semibold'
                    data-bs-toggle="collapse" data-bs-target="#guidelines" aria-expanded="false" aria-controls="guidelines">
                    <span>Guidelines</span>
                    <span><SlArrowDown className='arrowdown' /></span>
                </div>
                {/* Collapse Div for Guidelines */}
                <div className="collapse my-3" id="guidelines">
                    <p className='contents ps-4 fw-semibold'>
                        {event.guidelines}
                    </p>
                </div>
            </div>
        </>
    );
}

export default ProductDetail;
