import React, { useState } from 'react'
import Logo from '../../Assets/logo_224x61-02.png'
import sample from '../../Assets/sampleImage.jpg'
import './NavBar.css'
import { IoSearch } from "react-icons/io5"
import { Link, useNavigate } from 'react-router-dom'
import { CiLocationOn } from "react-icons/ci";
import { SlArrowDown } from "react-icons/sl";
import { AiOutlineEnvironment } from "react-icons/ai";
import { FiX } from "react-icons/fi";
import { TbUser } from "react-icons/tb";
import { BsList } from "react-icons/bs";
import { TbSitemap } from "react-icons/tb";
import { TbArrowLeftToArc } from "react-icons/tb";
import { TbArrowLeftFromArc } from "react-icons/tb";
import { API_URL } from '../../config'
import axios from "axios";
import { AiOutlineUser } from "react-icons/ai";
import { BsTicketDetailed } from "react-icons/bs";
import { BiFile } from "react-icons/bi";
import femaleProfilePic from '../../Assets/femaleprofile.png';
import maleProfilePic from '../../Assets/maleprofile.png';

function NavBar({ onNext, userLocation, onData }) {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const userLoggedIn = JSON.parse(localStorage.getItem('userData'));
    const locationModal = () => {
        onNext(true);
    };
    const highlightText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {parts.map((part, index) =>
                    part.toLowerCase() === highlight.toLowerCase() ? (
                        <span key={index} className="highlight">
                            {part}
                        </span>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        if (value) {
            handleSearch(value);
        } else {
            setResults([]);
        }
    };
    const handleSearch = async (searchQuery) => {
        try {
            const response = await axios.get(`${API_URL}/api/search`, {
                params: {
                    query: searchQuery,
                },
            });
            const res = response.data;
            setResults(res);
            if (typeof onData === 'function') {
                onData(res);
            }
        } catch (error) {
            setError('Error fetching data');
        }
    };
    const handleListUrEvent = (e) => {
        e.preventDefault()
        window.location.href = 'https://www.hostyourfrontseat.in';
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(query);
        }
    };
    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };
    return (
        <>
            <div className='row' id='largeScreenDiv'>
                <div className="navigationBar">
                    <div className='logoDiv'>
                        <Link to="/">
                            <img className='logoImg'
                                src={Logo} />
                        </Link>
                    </div>
                    <div className='navDiv'>
                        <div className='searchDiv'>
                            <div className='setcentersearch'>
                                <div className='search'>
                                    <IoSearch className="searchIcon" size={25} />
                                    <input className='search-input'
                                        placeholder='Search by Event, Artist, Venue...'
                                        value={query}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                {results.length > 0 && (
                                    <div className="dropdown searchSuggestions">
                                        <ul className="list-group dropdown-menu" style={{ width: "72%" }}>
                                            {results.map((result) => (
                                                <Link
                                                    to={`/productpage/${result._id}`}
                                                    key={result._id}
                                                    className="dropdown-item"
                                                    onClick={() => setQuery(result.event_name || result.Artist)}
                                                >
                                                    {highlightText(result.event_name || result.Artist, query)}
                                                </Link>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='navContent'>
                            <div className='listevent'>
                                <button className='btnlink'
                                    onClick={handleListUrEvent}> List Your Event</button >
                            </div>
                            <div className='locationDiv'>
                                <Link className='loactionlink' onClick={locationModal}>
                                    <CiLocationOn className='locationIcon' />
                                    {userLocation ?
                                        <span className='mx-1'> {userLocation}</span>
                                        : <span className='mx-1'> Your City</span>
                                    }

                                    <SlArrowDown className='ms-2' size={7} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='userDiv'>
                        <div className='user'>
                            {userLoggedIn ?
                                <Link to='/userprofile' className='userlink'>
                                    <TbUser className='fw-semibold fs-5 text-primary' />
                                    <span className='ps-1'> Hi, {userLoggedIn ? userLoggedIn?.firstName : "Guest"} </span>
                                </Link> :
                                <Link to='/login?source=login' className='userlink' >
                                    <TbUser className='fw-bold fs-5 text-primary' />
                                    <span className='ps-1'> Login </span>
                                </Link>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Div for Mobile Device */}
            <div className='row' id='smallScreenDiv'>
                <div className='navbar'>
                    <div className="dropdown togglebtn">
                        <BsList className='dropdown-toggle fs-1 ms-2 fw-bold'
                            data-bs-toggle="dropdown"
                            aria-expanded="false" aria-controls="responsiveNavbar"
                        />
                        <ul className="dropdown-menu collapseNavbar">
                            <div className='d-flex justify-content-end pe-2'>
                                <FiX className='text-dark' size={30} />
                            </div>
                            <li >
                                <div className='userProfile'>
                                    {
                                        userLoggedIn ? (userLoggedIn.gender === "female" ? <img className='userProfilePic' src={femaleProfilePic} alt='profilePic'></img>
                                            : <img className='userProfilePic' src={maleProfilePic} alt='profilePic' />
                                        ) : ''
                                    }
                                    {/* <img src={sample} className='userProfilePic' /> */}
                                    <h5 className='fw-bold'> Hi, {userLoggedIn ? userLoggedIn[0]?.firstName : "Guest"}</h5>
                                </div>
                            </li>
                            <li>
                                <div className='sidenavcontent'>
                                    <ul>
                                        <li>
                                            <Link className='link' onClick={locationModal}>
                                                <AiOutlineEnvironment className='text-dark' />
                                                {userLocation ?
                                                    <span className='ps-1 text-dark fw-semibold'> {userLocation}</span>
                                                    : <span className='ps-1 text-dark fw-semibold'> Your City</span>
                                                }
                                            </Link>
                                        </li>
                                        {/* <li className='nav-content' style={{ marginTop: "23px" }}>
                                            <Link to='/list-your-event' className='link'
                                                onClick={handleListUrEvent}>
                                                <TbSitemap className='text-dark' />
                                                <span className='ps-1 text-dark fw-semibold'> List Your Event </span>
                                            </Link >
                                        </li> */}
                                        {
                                            userLoggedIn ? <>
                                                <li className='nav-content' style={{ marginTop: "23px" }}>
                                                    <Link to="/userprofile" className='link-dark link-underline-opacity-0 fw-semibold'>
                                                        <AiOutlineUser /> <span>Profile</span>
                                                    </Link>
                                                </li>
                                                <li className='nav-content' style={{ marginTop: "23px" }}>
                                                    <Link to="/yourtickets" className='link-dark link-underline-opacity-0 fw-semibold'>
                                                        <BsTicketDetailed /> <span>Your Ticket</span>
                                                    </Link>
                                                </li>
                                                {/* <li className='nav-content' style={{ marginTop: "23px" }}>
                                                    <Link to="/hosted-events" className='link-dark link-underline-opacity-0 fw-semibold '>
                                                        <BiFile /> <span>Event Hosted</span>
                                                    </Link>
                                                </li> */}
                                                <li className='nav-content' style={{ marginTop: "23px" }}>
                                                    <Link to="#" className='link-dark link-underline-opacity-0 fw-semibold'>
                                                        <BiFile /> <span>Reward Points</span>
                                                    </Link>
                                                </li>

                                            </> : ''
                                        }
                                        <li className='nav-content' style={{ marginTop: "23px" }}>
                                            {userLoggedIn ?
                                                <Link to='/' className='link' onClick={handleLogout}>
                                                    <TbArrowLeftFromArc className='text-dark' />
                                                    <span className='ps-1 text-dark fw-semibold'> Logout </span>
                                                </Link> :
                                                <Link to='/login' className='link' >
                                                    <TbArrowLeftToArc className='text-dark' />
                                                    <span className='ps-1 text-dark fw-semibold'> Login </span>
                                                </Link>

                                            }
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='logo-Image'>
                        <Link to="/">
                            <img src={Logo} className='logomobileView' />
                        </Link>
                    </div>
                    <div className='searchbtn'>
                        <IoSearch className="searchIcon" size={30} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavBar